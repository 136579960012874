import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import logofooter from "../images/logofooter.svg"

export default function Footer() {
  // レイアウト調整対応中
  return (
    <footer className="syouei-footer">
      <ul className="syouei-footer-menu">
        <li className="syouei-footer-menu-list">
          <Link to="/" className="syouei-footer-a syouei-a syouei-font-regular">
            TOP
          </Link>
        </li>
        <li className="syouei-footer-menu-list">
          <Link
            to="/aboutus"
            className="syouei-footer-a syouei-a syouei-font-regular"
          >
            会社概要
          </Link>
        </li>
        <li className="syouei-footer-menu-list">
          <Link
            to="/careers"
            className="syouei-footer-a syouei-a syouei-font-regular"
          >
            採用情報
          </Link>
        </li>
        <li className="syouei-footer-menu-list">
          <Link
            to="/works"
            className="syouei-footer-a syouei-a syouei-font-regular"
          >
            施工情報
          </Link>
        </li>
      </ul>
      <Link to="/">
        <img src={logofooter} width="auto" height={12} />
      </Link>
      <p className="syouei-footer-copy syouei-font-en">© 2021 SYOUEI</p>
    </footer>
  )
}
