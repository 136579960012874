import * as React from "react"
import PropTypes from "prop-types"
import Menu from "./menu"
import { Link } from "gatsby"

import { Container, Navbar, Nav } from "react-bootstrap"
import { render } from "react-dom"
import logo from "../images/logo.svg"

const openMenu = () => {
  document.getElementById("syouei-menu-page").style.display = "block"
}

const Header = ({ siteTitle }) => {
  return (
    <div>
      <Menu />
      <header className="d-light d-sm-none syouei-whitehedder syouei-hedder-effect syouei-nav-sp">
        <nav className="navbar navbar-expand-sm navbar-light">
          <button
            aria-controls="navbarResponsive"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarResponsive"
            aria-label="Toggle navigation"
            className="navbar-toggler collapsed syouei-nav-sp-button syouei-nav"
            onClick={openMenu}
          >
            <span className="navbar-toggler-icon syouei-nav-sp-icon" />
          </button>
        </nav>
      </header>
      <header className="nav-pc syouei-hedder-effect syouei-whitehedder">
        <div className="container">
          <nav className="navbar navbar-light">
            <Link to="/" className="navbar-brand d-sm-none">
              <img src={logo} width="auto" height={22} />
            </Link>
            <Link to="/" className="navbar-brand d-none d-sm-block">
              <img
                src={logo}
                width="auto"
                height={42}
                className="syouei-logo-img"
              />
            </Link>
            <span className="navbar-text syouei-copy me-auto d-none d-lg-block">
              給油施設の新設工事、セルフ切替工事、各所補修メンテナンス工事
            </span>
            <button
              className="navbar-toggler d-none d-sm-block syouei-nav"
              type="button"
              aria-controls="navbarTogglerDemo01"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={openMenu}
            >
              <span className="navbar-toggler-icon" />
            </button>
          </nav>
        </div>
      </header>
      <div className="syouei-whitehedder syouei-bg-white d-none d-sm-block" />
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
