import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import navclose from "../images/navclose.png"

const closeMenu = () => {
  document.getElementById("syouei-menu-page").style.display = "none"
}

export default function Menu() {
  return (
    <div id="syouei-menu-page">
      <div className="container pt-4 pt-sm-5" onClick={closeMenu}>
        <img id="original-close" src={navclose} />
        <div className="clearfix"></div>
        <ul className="syouei-menu">
          <li className="syouei-menu-list">
            <Link to="/" className="syouei-a">
              <span className="syouei-menu-jp syouei-font-bold">トップ</span>
              <span className="syouei-menu-en syouei-font-en syouei-font-bold">
                TOP
              </span>
            </Link>
          </li>
          <li className="syouei-menu-list">
            <Link to="/aboutus" className="syouei-a">
              <span className="syouei-menu-jp syouei-font-bold">会社概要</span>
              <span className="syouei-menu-en syouei-font-en syouei-font-bold">
                ABOUT US
              </span>
            </Link>
          </li>
          <li className="syouei-menu-list">
            <Link to="/careers" className="syouei-a">
              <span className="syouei-menu-jp syouei-font-bold">採用情報</span>
              <span className="syouei-menu-en syouei-font-en syouei-font-bold">
                CAREERS
              </span>
            </Link>
          </li>
          <li className="syouei-menu-list">
            <Link to="/works" className="syouei-a">
              <span className="syouei-menu-jp syouei-font-bold">施工情報</span>
              <span className="syouei-menu-en syouei-font-en syouei-font-bold">
                WORKS
              </span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}
