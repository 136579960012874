import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

export default function Contact() {
  return (
    <div className="syouei-contact-bgcolor">
      <div className="syouei-contact">
        <div className="syouei-contact-contents">
          <div className="container">
            <div className="row">
              <div className="col-xl-2 col-sm-1 col-12" />
              <div className="col-xl-8 col-sm-10 col-12">
                <div className="row">
                  <div className="col-12 syouei-contact-height">
                    <div className="syouei-table-cell syouei-contact-top pt-4">
                      {/* <img src="_assets/images/test.png"> */}
                      <h2 className="syouei-font-bold syouei-contact-big">
                        お問い合わせ
                      </h2>
                      <p className="pt-3 d-none d-lg-block">
                        {" "}
                        採用に関するお問い合わせ・その他お問い合わせはこちらから
                      </p>
                      <p className="pt-3 d-md-block d-lg-none">
                        {" "}
                        各種お問い合わせはこちらから
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 syouei-contact-height syouei-contact-center">
                    <div className="syouei-table-cell syouri-contact-tel">
                      <div>
                        {/* <img src="_assets/images/test.png"> */}
                        <p className="syouei-font-bold">
                          お電話でのお問い合わせ
                        </p>
                        <p className="syouei-tel-num syouei-font-en">
                          080-3005-2641
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 syouei-contact-height syouei-contact-center">
                    <div className="syouei-table-cell">
                      {/* <img src="_assets/images/test.png"> */}
                      <div className="syouei-contact-mail">
                        <p className="syouei-font-bold">
                          メールでのお問い合わせ
                        </p>
                        <p className="syouei-mailad syouei-font-en">
                          syouei@sj9.so-net.ne.jp
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-sm-1 col-12" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
