/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
// import "./layout.css"
import "./syouei.css"
import Footer from "./footer"
import Contact from "./contact"
import "bootstrap/dist/css/bootstrap.min.css"
import "@fontsource/noto-sans-jp/400.css"
import "@fontsource/noto-sans-jp/300.css"
import "@fontsource/noto-sans-jp/700.css"
import "@fontsource/lexend-deca/300.css"
import "@fontsource/lexend-deca/500.css"
import "@fontsource/lexend-deca/700.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header />
      {/* <Header siteTitle={data.site.siteMetadata?.title || `Title`} /> */}
      <div>
        <main>{children}</main>
      </div>
      <Contact />
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
